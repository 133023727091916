import React, { useEffect } from 'react';
import { useStores } from "@/stores";
import { observer } from "mobx-react";
import PagePanel from "@components/PagePanel";
import { useRouter } from "next/router";
import { useFormik } from 'formik';
import InputText from "@components/InputText";
import Button from "@components/Button";
import { ClaimStore } from "@stores/claim.store";
import * as Yup from 'yup';
import InputKenteken from "@components/InputKenteken";
import isEmailValidator from 'validator/lib/isEmail';
import Head from "next/head";
import { checkEmailValid } from "@/clients/dieselgate.client";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Dit veld is verplicht')
    .test("is-valid-email", 'Dit is geen geldig e-mailadres', (value) => value ? isEmailValidator(value) : false)
    .test("is-valid-email-call", 'Dit is geen geldig e-mailadres', (email) => checkEmailValid(email).then(async res => {
      return res.valid;
    })),
  licensePlate: Yup.string()
    .matches(/^[A-Za-z0-9-]+$/, 'Dit is geen geldig kenteken')
    .required('Dit veld is verplicht')
    .test('length', 'Controleer uw kenteken, een kenteken bestaat minimaal uit 6 tekens.', (val: string) => {
      return val?.replace(/[^a-zA-Z0-9 ]/g, '').length > 5;
    })
    .test('length', 'Controleer uw kenteken, een kenteken mag niet meer dan 6 tekens bevatten', (val: string) => {
      return val?.replace(/[^a-zA-Z0-9 ]/g, '').length <= 6;
    })
});

const IndexPage = () => {
  const { claimStore } = useStores();
  const router = useRouter();
  const { kenteken, email, referral } = router.query;

  const formik = useFormik({
    initialValues: { licensePlate: '', email: '' },
    validationSchema: ValidationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      await router.push({
        pathname: '/claim/checken',
        query: { email: values.email, kenteken: values.licensePlate, referral: referral },
      })
    }
  });

  useEffect(() => {
    formik.setFieldValue('licensePlate', kenteken);
    formik.setFieldValue('email', email);
  }, [kenteken]);

  useEffect(() => {
    import('@bettercart/react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.pageView();
      })
  }, [])

  useEffect(() => {
    formik.setFieldValue('licensePlate', claimStore.licensePlate);
    formik.setFieldValue('email', claimStore.email);
  }, [claimStore]);

  return (
    <PagePanel>

      <Head>
        <title>Dieselgate - Check je diesel</title>
      </Head>

      <div className={ 'text-center flex flex-col gap-4' }>
        <h4>Check je diesel</h4>
        <p>Heb jij recht op een vergoeding?<br/>
          Check gratis je kenteken.</p>
      </div>

      <form onSubmit={ formik.handleSubmit } className={ 'flex flex-col gap-4' }>
        <InputKenteken
          name={ 'licensePlate' }
          label={ 'Kenteken' }
          type={ 'text' }
          disabled={ formik.isSubmitting }
          value={ formik.values.licensePlate?.toUpperCase() }
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          error={ formik.errors.licensePlate && formik.touched.licensePlate ? formik.errors.licensePlate : null }
          pattern={ '[A-z0-9-]+' }
        />

        <InputText
          name={ 'email' }
          label={ 'Email' }
          type={ 'text' }
          disabled={ formik.isSubmitting }
          value={ formik.values.email }
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          error={ formik.errors.email && formik.touched.email ? formik.errors.email : null }
        />

        <div className={ 'flex flex-col mt-4 gap-4' }>
          <Button
            onClick={ formik.handleSubmit }
            type={ 'submit' }
            variant={ 'primary' }
            disabled={ formik.isSubmitting }
            isLoading={ formik.isSubmitting }
          >
            Check je Diesel
          </Button>

          <p className={ 'text-center text-sm' }>Door de kentekencheck te doen ga je akkoord met
            onze <a href={ 'https://dieselgate.com/privacy/' } target={ '_blank' }><u
              className={ 'cursor-pointer' }>privacyverklaring</u></a>.</p>
        </div>
      </form>

    </PagePanel>
  )
};

export default observer(IndexPage)