
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/claim/kenteken",
      function () {
        return require("private-next-pages/claim/kenteken/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/claim/kenteken"])
      });
    }
  