import React from 'react';
import { ExclamationCircleIcon } from "@heroicons/react/solid";

interface InputKentekenProps extends React.HTMLProps<HTMLInputElement> {
  onChange: (e) => void,
  label: string;
  error?: string;
}

const InputKenteken = (props: InputKentekenProps) => {

  const {
    name, label, placeholder, error, type, ...rest
  }: InputKentekenProps = props;


  return (
    <div key={ `${ name }-input` }>
      <div
        className={ `transition-all relative bg-white border-[5px] border-[#F3BB1F] rounded-[10px] px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-[#F3BB1F] focus-within:border-[#F3BB1F]` }>
        <div
          className={ 'absolute left-0 top-0 bottom-0 bg-[#0452C5] w-[30px] flex flex-col items-center justify-center rounded-l-[5px]' }>
          <div className={ 'border-dotted border-amber-400 h-4 w-4 border-[1px] rounded-full z-10' }/>
          <div
            className={ 'w-4 h-4 text-center mt-1 border-dashed border-amber-400 z-10 text-white font-semibold text-[10px]' }>NL
          </div>
        </div>
        <label htmlFor={ name } className={ 'pl-[30px] block text-xs font-medium text-gray-800' }>
          { label }
        </label>
        {/*// @ts-ignore*/ }
        <input
          id={ name }
          name={ name }
          type={ type }
          className={ 'block w-full pl-[30px] border-none text-md focus:ring-0 p-0 text-gray-900 placeholder-gray-400 focus:outline-none' }
          placeholder={ placeholder }
          { ...rest }
        />
        { error && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
        </div> }
      </div>
      { error && <p className={ 'mt-2 text-sm text-red-600' }>{ error }</p> }
    </div>
  )
}

export default InputKenteken;
